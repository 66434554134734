import React from "react";
import { Link, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Cookies from "js-cookie";
import moment from "moment";
import "moment/locale/ru";

moment.locale("ru");
import Routes from "Configs/routes";
import { replaceStaticHost, webpTransformer } from "Helpers/ImageManager";
import { sendMetriks } from "Services/sendMetriks";
import { TARIFF_TV } from "Services/queries";
import { generateUrl } from "Services/withDefaultParams";
import Metrika from "Services/Metrika";
import { YANDEX_TARGETS } from "Constants/metriks/yandex-targets";
import {
	EVENT_CATEGORY_CLICK_BUTTON,
	EVENT_CATEGORY_LEAD,
} from "Constants/metriks/GoogleCategorys";
import {
	EVENT_ACTION_CONNECT_PROVIDER,
	CLICK_REDIRECT_LANDING,
} from "Constants/metriks/GoogleActions";
import { svgAction } from "Constants/SVG";
import { getDeclination } from "Utils/text";
import { OfferLayout as MicroMarkupOfferLayout } from "Layouts/MicroMarkup";
import ConnectButton from "Containers/Buttons/ConnectButton";
import {
	TariffsRow,
	ProviderLogo,
	MeasureA,
	MeasurePrice,
	DodysList,
	MobileData,
} from "ComponentsUI/TarifficationB";
import { ModalLayout } from "ComponentsUI/Modallayout";
import { Tooltip, TooltipBody } from "ComponentsUI/Tooltip";
import { AdditionalSim } from "ComponentsUI/AdditionalSim";

@withRouter
@inject("ApplicationModel")
@observer
export default class PlansBTableContainer extends React.Component {
	/*onReviewClick() {
        let modalModel = this.props.ApplicationModel.modalModel;
        let text = this.props.ApplicationModel.text;
        import("Containers/RatesReviews/index").then(RatesReviewsContainer => {
            modalModel.toggleModal(
                <ModalLayout closeModal={() => this.closeModal()}>
                    <RatesReviewsContainer.default
                        text={text}
                        provider={this.props.data.provider}
                    />
                </ModalLayout>
            );
        });
    }*/

	closeModal() {
		let modalModel = this.props.ApplicationModel.modalModel;
		if (modalModel.isOpen) {
			modalModel.toggleModal();
		}
	}

	// Клик по количеству каналов
	onClickAddHandlerTv() {
		if (!!this.props.data.tv.use_text) {
			return;
		}

		this.closeModal();
		let modalModel = this.props.ApplicationModel.modalModel;
		let promise1 = import("Containers/ChannelsContainer");
		let apolloTariffQueryPromise = this.props.ApplicationModel.apolloTransportLayer.query({
			query: TARIFF_TV,
			variables: {
				id: this.props.data.id,
			},
		});
		Promise.all([promise1, apolloTariffQueryPromise]).then((modules) => {
			let ChannelsContainer = modules[0].default;
			let tariff = modules[1].data.tariffsOne;
			modalModel.toggleModal(
				<ModalLayout closeModal={() => this.closeModal()}>
					<ChannelsContainer tariff={tariff} />
				</ModalLayout>
			);
		});
	}

	// Клик по кнопке подключить
	clickConnectHandler() {
		let {
			ApplicationModel: { tariffsModel, sendingStore },
		} = this.props;
		let licenseFree = false;
		let connectTariffButton =
			this.props.ApplicationModel?.siteConfig?.settings?.connect_tariff_button?.[
				this.props.pageName
			] || "connect";

		let tariff = this.props.data;

		if (
			tariff?.after?.data?.price?.license_fee_month &&
			tariff?.after?.data?.price?.license_fee_month !== tariff.price.license_fee
		) {
			licenseFree = true;
		}
		if (licenseFree && connectTariffButton === "connect") {
			Metrika.getInstance().yandex.sendEvent(YANDEX_TARGETS.CLICK_CONNECT_WITH_ACTION, []);
		}

		if (this.props.compareIsHideInConnectTariffs) {
			Metrika.getInstance().yandex.sendEvent(
				YANDEX_TARGETS.NEDAVNO_PODCLUCHENNYE_KARTOCHKA_PROVA,
				[]
			);
		}

		if (this.props.orderToHomeCompareBtnHidden) {
			Metrika.getInstance().yandex.sendEvent(
				YANDEX_TARGETS.NEDAVNO_PODCLUCHENNYE_ORDERS_TOHOME,
				[]
			);
		}

		if (this.props.isProviderCatalog && connectTariffButton !== "check_coverage") {
			Metrika.getInstance().yandex.sendEvent(YANDEX_TARGETS.CLICK_TARIFF_FROM_CATALOG, []);
		}

		if (this.props.isRegionRatesTag && connectTariffButton === "connect") {
			Metrika.getInstance().yandex.sendEvent(
				YANDEX_TARGETS.CLICK_CONNECT_TARIFF_FROM_TAG_RATES,
				[]
			);
		}

		if (this.props.isRegionRatesTag && connectTariffButton === "check_coverage") {
			Metrika.getInstance().yandex.sendEvent(YANDEX_TARGETS.CLICK_CHECK_ADDRESS_RATES_TAG, []);
		}

		if (this.props.isRating) {
			Metrika.getInstance().yandex.sendEvent(YANDEX_TARGETS.CLICK_TARIFF_FROM_RATING, []);
		}

		if (this.props.isReviewsProviders) {
			sendMetriks("CLICK_TARIFF_FROM_OTZYVY_VIP");
		}

		if (this.props.isRates) {
			Metrika.getInstance().yandex.sendEvent(
				YANDEX_TARGETS.CLICK_CONNECT_TARIF_FROM_KARTOCHKA_RATES,
				[]
			);
		}

		if (this.props.isProviderRatesTags && connectTariffButton === "connect") {
			Metrika.getInstance().yandex.sendEvent(
				YANDEX_TARGETS.CLICK_CONNECT_TARIF_FROM_TAG_KARTOCHKA_RATES,
				[]
			);
		}
		if (this.props.isProviderRatesTags && connectTariffButton === "check_coverage") {
			Metrika.getInstance().yandex.sendEvent(
				YANDEX_TARGETS.CLICK_CHECK_ADDRESS_RATES_TAG_PROVIDER,
				[]
			);
		}

		if (this.props.isCard) {
			Metrika.getInstance().yandex.sendEvent(
				YANDEX_TARGETS.CLICK_CONNECT_TARIF_FROM_KARTOCHKA_O_PROVIDERE,
				[]
			);
		}

		if (this.props.isActionRegion) {
			sendMetriks("CLICK_CHECK_ADRESS_FROM_ACTIONS");
		}

		if (
			this.props.data?.provider?.vip_provider == 1 &&
			this.props.data?.provider?.provider_settings?.tariffs?.value === "button_text" &&
			connectTariffButton === "connect"
		) {
			sendMetriks("CLICK_NEW_TEXT");
		}

		if (Routes.RatesInHouse.route === this.props.match.path) {
			Cookies.set("connectTariffFrom", "from_popular_house");
		} else if (Routes.AddressStreet.route === this.props.match.path) {
			Cookies.set("connectTariffFrom", "from_street");
		} else if (Routes.AddressStreets.route === this.props.match.path) {
			Cookies.set("connectTariffFrom", "from_district");
		} else if (!!this.props.isProviderCatalog) {
			Cookies.set("connectTariffFrom", "catalog");
		} else if (!!this.props.isRegionRatesTag) {
			Cookies.set("connectTariffFrom", "rates_tags");
		} else if (!!this.props.isCardPage) {
			Cookies.set("connectTariffFrom", "kartochka_ne_vip");
		} else if (!!this.props.isOrderToHome) {
			Cookies.set("connectTariffFrom", "orders_tohome");
		} else if (!!this.props.isTS) {
			Cookies.set("connectTariffFrom", "TC");
		} else if (!!this.props.isExclusives || !!this.props.isMainPage) {
			Cookies.set("connectTariffFrom", "exclusive");
		} else if (!!this.props.isAboutProvider) {
			Cookies.set("connectTariffFrom", "kartochka_vip_about_provider");
		} else if (!!this.props.isActions) {
			Cookies.set("connectTariffFrom", "kartochka_vip_actions");
		} else if (!!this.props.isRates) {
			Cookies.set("connectTariffFrom", "rates_provider");
		} else if (!!this.props.isProviderRatesTags) {
			Cookies.set("connectTariffFrom", "tag_rates_provider");
		} else if (!!this.props.isRating) {
			Cookies.set("connectTariffFrom", "rating");
		} else if (!!this.props.isCard) {
			Cookies.set("connectTariffFrom", "kartochka_prova");
		} else if (this.props.is404) {
			Cookies.set("connectTariffFrom", "from_404");
		} else if (this.props.isActionRegion) {
			Cookies.set("connectTariffFrom", "actions_in_region");
		} else if (this.props.isReviewsProviders) {
			Cookies.set("connectTariffFrom", "vip_otzyvy");
		} else {
			Cookies.set("connectTariffFrom", "");
		}

		Metrika.getInstance().google.sendEvent(
			EVENT_CATEGORY_CLICK_BUTTON,
			EVENT_ACTION_CONNECT_PROVIDER
		);

		if (this.props.isTS || this.props.isActions) {
			sendMetriks("CLICK_CONNECT_TARIF");
		}

		const dataLogs = {
			click_on_page: "open_connect_tariff",
		};
		sendingStore.sendClientAction(dataLogs);

		tariffsModel.setTariff(tariff);
	}

	onClickHref = () => {
		window.open(this.props.data.provider.contacts.link, "_blank");
		Metrika.getInstance().google.sendEvent(EVENT_CATEGORY_LEAD, CLICK_REDIRECT_LANDING);

		return false;
	};

	onClickHandler() {
		// let providerModel = this.props.ApplicationModel.providerModel;
		// providerModel.changeTab("provider_tab_card");
	}

	getChannelDeclination(count) {
		const { text } = this.props.ApplicationModel;

		return getDeclination(
			count,
			text.SERVICES_CHANNEL_SUFFIX.ONE,
			text.SERVICES_CHANNEL_SUFFIX.TWO,
			text.SERVICES_CHANNEL_SUFFIX.MANY
		);
	}

	render() {
		let texts = this.props.ApplicationModel.text;
		let compareModel = this.props.ApplicationModel.compareTarifficationsModel;
		let data = this.props.data;
		let windowSizeModel = this.props.ApplicationModel.windowSizeModel;
		let showActionButton = false;
		let siteConfig = this.props.ApplicationModel.siteConfig;

		// Количество отзывов и рейтинг только по домашнему интернету
		const provider = data.provider;
		const orderType = provider?.rating?.order_types?.find((x) => x.order_type_id === 1);

		let position = orderType?.position;
		let positionInRating = position ? position : null;
		let in_main_rating = orderType?.in_main_rating;

		let reviews_count = orderType?.review_all;

		let districtsArr = [];

		data.districts?.map((item) => {
			districtsArr.push(item.name);
		});
		let newDistricts = districtsArr.join(", ");

		const providerReviewUrl = generateUrl(Routes.ProvidersReviewListOld.route, {
			regionUrl: data.region.url,
			providerName: data.provider.url_name,
		});

		// Логотип
		let providerLogo = !this.props.hideProviderLogo ? (
			<ProviderLogo
				viewPopup={!this.props.isReviewsProviders}
				providerReviewUrl={providerReviewUrl}
				texts={texts}
				path={webpTransformer(replaceStaticHost(data.provider.logo_2))}
				reviewsCounter={reviews_count || 0}
				rating={provider?.rating && in_main_rating}
				name={`${data.provider.name}`}
				position={positionInRating}
				windowSizes={windowSizeModel.windowSizes}
			/>
		) : null;

		// Скорость
		let speedComponent =
			data.internet && data.internet.speed_in ? (
				<MeasureA
					value={data.internet && data.internet.speed_in}
					typeA={true}
					measure={texts.MBPS}
				/>
			) : null;

		// Каналы
		let channels = null;

		if (data.tv && (data.tv.channels || data.tv.channels_hd)) {
			channels = data.tv.channels;
			if (data.tv.channels_hd) {
				channels = !!channels
					? channels + " + " + data.tv.channels_hd + "HD"
					: data.tv.channels_hd + "HD";
			}
		}

		const channelsCount = (data.tv?.channels || 0) + (data.tv?.channels_hd || 0);

		let channelsDeclination = getDeclination(
			channelsCount,
			texts.SERVICES_CHANNEL_DECLINATION.ONE,
			texts.SERVICES_CHANNEL_DECLINATION.TWO,
			texts.SERVICES_CHANNEL_DECLINATION.FIVE
		);

		let channelsComponent = channels ? (
			<MeasureA
				value={channels}
				typeA={true}
				onClickHandler={() => this.onClickAddHandlerTv()}
				usePointer={!data.tv.use_text}
				measure={channelsDeclination}
			/>
		) : null;

		let mobileDataComponent = null;

		if (!!data.mobile_new) {
			let mobileData = data.mobile_new;
			let internetValue = parseInt(mobileData.internet);
			let callsValue = parseInt(mobileData.calls);
			let smsValue = parseInt(mobileData.sms);

			mobileDataComponent = (
				<MobileData
					data={[
						{
							value: internetValue,
							measure: texts.gb,
							type: mobileData.internet_type,
						},
						{
							value: callsValue,
							measure: texts.min,
							type: mobileData.calls_type,
						},
						{
							value: smsValue,
							measure: texts.sms,
							type: mobileData.sms_type,
						},
					]}
				/>
			);
		}

		let connectText = !!data.connection?.price
			? `${data.connection.price} ${texts.SERVICES_RUB}`
			: texts.servicesFree;
		let prepaidText = data.price.system_calculation_connect
			? `${texts.AVANS} ${data.price.system_calculation_connect} ${texts.SERVICES_RUB}`
			: "";
		// Опции тарифа
		let dodyComponent =
			!!data.dody && !!data.dody.length ? (
				<DodysList
					items={[...data.dody]}
					windowSizeModel={windowSizeModel}
					connectText={connectText}
					prepaidText={prepaidText}
					texts={texts}
				/>
			) : null;

		// Акция
		let actionImageComponent =
			data.dody[4].state === 1 ? (
				<svg
					width={"49px"}
					height={"11px"}
					viewBox={"0 0 49 11"}
					fill={"none"}
					xmlns={"http://www.w3.org/2000/svg"}
				>
					<path d={svgAction} fill={"#5813FE"} />
				</svg>
			) : null;

		// Цена
		let valuePrice = {
			price: `${data.displayPrice}`,
		};
		if (
			data?.after?.data?.price?.license_fee_month &&
			data?.after?.data?.price?.license_fee_month !== data.price.license_fee
		) {
			valuePrice.sale = `${data.price.license_fee}`;
		}

		let priceMeasure = null;
		if (data.price.license_fee_interval.code === "month") {
			priceMeasure = texts.SUBSCRIPTION_FEE_TITLE;
		}
		if (data.price.license_fee_interval.code === "day") {
			priceMeasure = texts.PRICE_IN_DAY;
		}

		let priceComponent = null;

		if (valuePrice.sale) {
			let textTimeGracePeriod = "";

			if (!data?.after.rule.month_cnt || data?.after.rule.month_cnt === 0) {
				textTimeGracePeriod = `до ${moment(data?.after.rule.date_end).format("D MMMM YYYY")}`;
			} else {
				textTimeGracePeriod = `${data?.after.rule.month_cnt} ${texts.SERVICES_MONTH}`;
			}

			let salePeriodText = (
				<div>
					<div>{`${texts.ORDER_FORM_GRACE_PERIOD_TITLE}:`}</div>
					<div>{`${textTimeGracePeriod}`}</div>
				</div>
			);

			priceComponent = (
				<Tooltip
					body={<TooltipBody priceSale={true}>{salePeriodText}</TooltipBody>}
					priceSale={true}
					v3={true}
				>
					<MeasurePrice
						value={valuePrice.price}
						valueSale={valuePrice.sale}
						measure={priceMeasure}
						withAsterisk={true}
					/>
				</Tooltip>
			);
		} else {
			priceComponent = (
				<MeasurePrice value={valuePrice.price} valueSale={valuePrice.sale} measure={priceMeasure} />
			);
		}

		let providerUrl = generateUrl(Routes.ProviderRates.route, {
			regionUrl: data.region.url,
			providerName: data.provider.url_name,
		});
		let providerNameComponent =
			!this.props.hideProviderLogo && !this.props.isRates ? (
				<Link to={providerUrl}>{data.provider.name}</Link>
			) : null;
		let providerDistrictsComponent1 =
			!!this.props.hideProviderLogo && !!data.districts ? `(${newDistricts})` : null;
		let providerDistrictsComponent2 = !!data.districts ? `(${newDistricts})` : null;
		let providerDistrictsComponent =
			providerDistrictsComponent1 || providerDistrictsComponent2 || null;
		let regionComponent = !!this.props.isRates ? `(${data.region.name})` : null;
		if (valuePrice.sale) {
			showActionButton = true;
		}

		const convergentOffer = this.props.data?.convergent_data ? (
			<AdditionalSim
				text={texts}
				convergentPrice={this.props.data.convergent_data?.displayPrice}
				tariffPrice={this.props.data?.displayPrice}
			/>
		) : null;

		let buttonConnect = (
			<ConnectButton
				data={data}
				showActionButton={showActionButton}
				compareIsHideInConnectTariffs={this.props.compareIsHideInConnectTariffs}
				orderToHomeCompareBtnHidden={this.props.orderToHomeCompareBtnHidden}
				pageName={this.props.pageName}
				action={this.props.action}
				recently={this.props.recently}
				isRates={this.props.isRates}
				isCard={this.props.isCard}
				onClickHref={this.onClickHref}
			/>
		);

		return (
			<MicroMarkupOfferLayout
				tariff={data}
				siteConfig={siteConfig}
				update_date={this.props.update_date}
				skipLayout={this.props.skipLayout}
			>
				<TariffsRow
					data={data}
					name={this.props.data.name}
					compareIsHideInConnectTariffs={this.props.compareIsHideInConnectTariffs}
					orderToHomeCompareBtnHidden={this.props.orderToHomeCompareBtnHidden}
					compareIsHide={this.props.compareIsHide}
					providerNameComponent={providerNameComponent}
					providerDistrictsComponent={providerDistrictsComponent}
					logoComponent={providerLogo}
					speedComponent={speedComponent}
					channelsComponent={channelsComponent}
					mobileDataComponent={mobileDataComponent}
					priceComponent={priceComponent}
					actionImageComponent={actionImageComponent}
					dodyComponent={dodyComponent}
					buttonConnectComponent={buttonConnect}
					inCompare={compareModel.findTariff(data)}
					onCompareClickHandler={() => compareModel.addToCompare(data)}
					texts={texts}
					connectButtonDatatest={"providers_form_inspect_connect_tariff_button"}
					onClickConnect={() => this.clickConnectHandler()}
					onClickHandler={() => this.onClickHandler()}
					hideProviderLogo={this.props.hideProviderLogo}
					regionNameComponent={regionComponent}
					windowSizes={windowSizeModel.windowSizes}
					convergentOffer={convergentOffer}
					exclusive={!!data.is_exclusive}
				/>
			</MicroMarkupOfferLayout>
		);
	}
}
